import React from 'react';
import './App.css';
import 'primeflex/primeflex.css';
import {
  Route,
  BrowserRouter
} from "react-router-dom";
import Admin from './Pages/Admin/Admin';
import Chart from 'chart.js';

const App = () => {

  return (
    <div>
      <BrowserRouter>
        <Route exact path="/" component={Admin} />
      </BrowserRouter>
    </div>
  );
}

export default App;
