import { values } from 'lodash';
import { COLOR_FEMALE, COLOR_MALE, LABEL_FEMALE, LABEL_MALE } from '../../Models/Constants';
import './ChartPeople.css';

type ChartPeopleProps = {
    totalLabel: string;
    chartData: ChartData[];
    chartTypeId: number;
    groupByFieldName: string;
    onClickElement: (data: ChartClickEventData) => void;
}

const ChartPeople = ({ totalLabel, chartData, chartTypeId, groupByFieldName, onClickElement }: ChartPeopleProps) => {
    let maleCount = 0;
    let femaleCount = 0;

    if (chartData.length > 0) {
        maleCount = chartData.find(x => x.groupBy === LABEL_MALE)?.data || 0;
        femaleCount = chartData.find(x => x.groupBy === LABEL_FEMALE)?.data || 0;
    }

    const onClick = (value: string) => {
        if (onClickElement) {
            onClickElement({
                chartTypeId: chartTypeId,
                fieldName: '',
                fieldValue: '',
                groupByFieldName: groupByFieldName,
                groypByFieldValue: value,
            } as ChartClickEventData)
        }
    }

    return (
        <div className="p-grid p-pt-3 p-justify-center">
            <div className="p-col-0 p-grid p-dir-col p-justify-center">
                <div className="p-col p-text-bold p-text-center">{totalLabel}</div>
                <div className="p-col p-text-center"><img className="people-image" src="AllPeople.png" alt={totalLabel} style={{ cursor: 'pointer' }} onClick={() => onClick('')} /></div>
                <div className="p-col p-text-bold p-text-center">{(maleCount + femaleCount).toLocaleString('en-US')}</div>
            </div>
            <div className="p-col-0 p-grid p-dir-col p-justify-center p-ml-1 p-mr-1">
                <div className="p-col p-text-bold p-text-center" style={{ color: COLOR_MALE }}>{(maleCount + femaleCount) > 0 ? (maleCount / (maleCount + femaleCount) * 100).toFixed(0) + '%' : '0%'}</div>
                <div className="p-col p-text-center"><img className="people-image" src="Male.png" alt={totalLabel} style={{ cursor: 'pointer' }} onClick={() => onClick(LABEL_MALE)} /></div>
                <div className="p-col p-text-bold p-text-center" style={{ color: COLOR_MALE }}>{maleCount.toLocaleString('en-US')}</div>
            </div>
            <div className="p-col-0 p-grid p-dir-col p-justify-center">
                <div className="p-col p-text-bold p-text-center" style={{ color: COLOR_FEMALE }}>{(maleCount + femaleCount) > 0 ? (femaleCount / (maleCount + femaleCount) * 100).toFixed(0) + '%' : '0%'}</div>
                <div className="p-col p-text-center"><img className="people-image" src="Female.png" alt={totalLabel} style={{ cursor: 'pointer' }} onClick={() => onClick(LABEL_FEMALE)} /></div>
                <div className="p-col p-text-bold p-text-center" style={{ color: COLOR_FEMALE }}>{femaleCount.toLocaleString('en-US')}</div>
            </div>
        </div>
    )
}

export default ChartPeople;