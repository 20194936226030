export const MAX_UPLOAD_SIZE_MB = 30;

export const LABEL_MALE = 'Male';
export const LABEL_FEMALE = 'Female';
export const LABEL_BLANK = '(Blank)';

export const COLOR_MALE = '#8C8D86';
export const COLOR_FEMALE = '#E6C069';

export const CHART_FONT_COLOR = '#000000';


export const DASHBOARD_AREA_TOTALS = 1;
export const DASHBOARD_AREA_GENDER = 2;
export const DASHBOARD_AREA_ETHNICITY = 3;
export const DASHBOARD_AREA_TERM_GENDER = 4;
export const DASHBOARD_AREA_TERM_ETHNICITY = 5;


export const CHART_TYPE_GENDER = 1;
export const CHART_TYPE_ETHNICITY = 2;
export const CHART_TYPE_AGE_BAND = 3;
export const CHART_TYPE_HIRE_BY_GENDER = 4;
export const CHART_TYPE_PROMOTION_BY_GENDER = 5;
export const CHART_TYPE_ATTRITION_RATE_BY_GENDER = 6;
export const CHART_TYPE_HIRE_MANAGEMENT_LEVEL_BY_GENDER = 8;
export const CHART_TYPE_HIRING_TREND_BY_ETHNICITY = 9;
export const CHART_TYPE_PROMOTION_TREND_BY_ETHNICITY = 10;
export const CHART_TYPE_ATTRITION_RATE_BY_ETHNICITY = 11;
export const CHART_TYPE_HIRE_MANAGEMENT_LEVEL_BY_ETHNICITY = 13;

export const CHART_TYPE_JOB_GROUP_LEVEL_1_BY_GENDER = 15;
export const CHART_TYPE_MANAGEMENT_LEVEL_BY_GENDER = 16;
export const CHART_TYPE_JOB_GROUP_LEVEL_2_BY_GENDER = 18;
export const CHART_TYPE_YOY_HEAD_COUNT_TREND_BY_GENDER = 19;

export const CHART_TYPE_TOTAL_BY_ETHNICITY = 20;
export const CHART_TYPE_JOB_GROUP_LEVEL_1_BY_ETHNICITY = 22;
export const CHART_TYPE_MANAGEMENT_LEVEL_BY_ETHNICITY = 23;
export const CHART_TYPE_JOB_GROUP_LEVEL_2_BY_ETHNICITY = 25;
export const CHART_TYPE_YOY_HEAD_COUNT_TREND_BY_ETHNICITY = 26;

export const CHART_TYPE_TERM_TYPE_BY_GENDER = 27;
export const CHART_TYPE_MANAGEMENT_LEVEL_ALL_BY_GENDER = 28;
export const CHART_TYPE_MANAGEMENT_LEVEL_TERMS_BY_GENDER = 29;
export const CHART_TYPE_JOB_GROUP_LEVEL_1_ALL_BY_GENDER = 31;
export const CHART_TYPE_JOB_GROUP_LEVEL_1_TERMS_BY_GENDER = 33;

export const CHART_TYPE_TERM_TYPE_BY_ETHNICITY = 34;
export const CHART_TYPE_MANAGEMENT_LEVEL_ALL_BY_ETHNICITY = 35;
export const CHART_TYPE_MANAGEMENT_LEVEL_TERMS_BY_ETHNICITY = 36;
export const CHART_TYPE_JOB_GROUP_LEVEL_1_ALL_BY_ETHNICITY = 38;
export const CHART_TYPE_JOB_GROUP_LEVEL_1_TERMS_BY_ETHNICITY = 40;



export const CHART_MIN_PERCENTAGE_LABEL = 3;


export const DATA_FILTER_FIELD_TYPE_MULTISELECT = 1;
export const DATA_FILTER_FIELD_TYPE_DATE = 2;
export const DATA_FILTER_FIELD_TYPE_NUMBER = 3;

export const UPLOAD_HEADERS = [
    'Person #',
    'Hire Date',
    'Termination Date',
    'Termination Type',
    'Termination Reason',
    'Job Group Level 1',
    'Job Group Level 2',
    'Location',
    'Management Level',
    'FTE',
    'Gender',
    'Age',
    'Count',
    'YE',
    'As Of:',
    'Ethnicity',
    'Age Band',
    'Hire Yr',
    'FT/PT',
    'Worker Status',
    'Promotion',
]

export const DATA_FIELDS = [
    {
        fieldName: 'personNum',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Person Num',
    } as DataFieldRow,
    {
        fieldName: 'hireDate',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Hire Date',
    } as DataFieldRow,
    {
        fieldName: 'terminationDate',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Termination Date',
    } as DataFieldRow,
    {
        fieldName: 'terminationType',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Termination Type',
    } as DataFieldRow,
    {
        fieldName: 'terminationReason',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Termination Reason',
    } as DataFieldRow,
    {
        fieldName: 'jobGroupLevel1',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Job Group Level 1',
    } as DataFieldRow,
    {
        fieldName: 'jobGroupLevel2',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Job Group Level 2',
    } as DataFieldRow,
    {
        fieldName: 'location',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Location',
    } as DataFieldRow,
    {
        fieldName: 'managementLevel',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Management Level',
    } as DataFieldRow,
    {
        fieldName: 'fte',
        fieldType: DATA_FILTER_FIELD_TYPE_NUMBER,
        label: 'FTE',
    } as DataFieldRow,
    {
        fieldName: 'gender',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Gender',
    } as DataFieldRow,
    {
        fieldName: 'age',
        fieldType: DATA_FILTER_FIELD_TYPE_NUMBER,
        label: 'Age',
    } as DataFieldRow,
    {
        fieldName: 'count',
        fieldType: DATA_FILTER_FIELD_TYPE_NUMBER,
        label: 'Count',
    } as DataFieldRow,
    {
        fieldName: 'ye',
        fieldType: DATA_FILTER_FIELD_TYPE_NUMBER,
        label: 'YE',
    } as DataFieldRow,
    {
        fieldName: 'asOf',
        fieldType: DATA_FILTER_FIELD_TYPE_NUMBER,
        label: 'As Of',
    } as DataFieldRow,
    {
        fieldName: 'ethnicity',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Ethnicity',
    } as DataFieldRow,
    {
        fieldName: 'ageBand',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Age Band',
    } as DataFieldRow,
    {
        fieldName: 'generationName',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Generation',
    } as DataFieldRow,
    {
        fieldName: 'subGenerationName',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Sub-Generation',
    } as DataFieldRow,
    {
        fieldName: 'hireYr',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Hire Yr',
    } as DataFieldRow,
    {
        fieldName: 'fulltimePartime',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Fulltime Partime',
    } as DataFieldRow,
    {
        fieldName: 'workerStatus',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Worker Status',
    } as DataFieldRow,
    {
        fieldName: 'promotion',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Promotion',
    } as DataFieldRow,
]

export const DATA_FILTER_FIELDS = [
    {
        fieldName: 'terminationType',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Termination Type',
    } as DataFieldRow,
    {
        fieldName: 'terminationReason',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Termination Reason',
    } as DataFieldRow,
    {
        fieldName: 'jobGroupLevel1',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Job Group Level 1',
    } as DataFieldRow,
    {
        fieldName: 'jobGroupLevel2',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Job Group Level 2',
    } as DataFieldRow,
    {
        fieldName: 'location',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Location',
    } as DataFieldRow,
    {
        fieldName: 'managementLevel',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Management Level',
    } as DataFieldRow,
    {
        fieldName: 'fte',
        fieldType: DATA_FILTER_FIELD_TYPE_NUMBER,
        label: 'FTE',
    } as DataFieldRow,
    {
        fieldName: 'gender',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Gender',
    } as DataFieldRow,
    {
        fieldName: 'age',
        fieldType: DATA_FILTER_FIELD_TYPE_NUMBER,
        label: 'Age',
    } as DataFieldRow,
    {
        fieldName: 'ethnicity',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Ethnicity',
    } as DataFieldRow,
    {
        fieldName: 'ageBand',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Age Band',
    } as DataFieldRow,
    {
        fieldName: 'generationName',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Generation',
    } as DataFieldRow,
    {
        fieldName: 'subGenerationName',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Sub-Generation',
    } as DataFieldRow,
    {
        fieldName: 'hireYr',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Hire Yr',
    } as DataFieldRow,
    {
        fieldName: 'fulltimePartime',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Fulltime Partime',
    } as DataFieldRow,
    {
        fieldName: 'workerStatus',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Worker Status',
    } as DataFieldRow,
    {
        fieldName: 'promotion',
        fieldType: DATA_FILTER_FIELD_TYPE_MULTISELECT,
        label: 'Promotion',
    } as DataFieldRow,
]