import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Sidebar } from 'primereact/sidebar';
import { useState } from 'react';
import { useWindowSize } from '../../Helpers/Hooks';
import FilterField from '../FilterField/FilterField';
import './FiltersComponent.css';

type FiltersComponentProps = {
    fields: DataFieldRow[] | undefined;
    filterSidebarVisible: boolean;
    setFilterSidebarVisible: (visible: boolean) => void;
    onFiltersChanged: (filters: DataFilterRow[] | undefined) => void;
}

const FiltersComponent = ({ fields, filterSidebarVisible, setFilterSidebarVisible, onFiltersChanged }: FiltersComponentProps) => {
    const [tempFilters, setTempFilters] = useState<DataFilterRow[] | undefined>(undefined)
    const [filters, setFilters] = useState<DataFilterRow[] | undefined>(undefined)

    const size = useWindowSize();

    const onFilterApply = () => {
        onFiltersChanged(tempFilters);
        setFilterSidebarVisible(false);
    }

    const onFilterCancel = () => {
        setTempFilters(filters);
        setFilterSidebarVisible(false);
    }

    const onFilterClear = () => {
        setFilters(undefined);
        setTempFilters(undefined);
        onFiltersChanged(undefined);
        setFilterSidebarVisible(false);
    }

    const onFilterChanged = (filter: DataFilterRow) => {

        let changed = false;

        let newFilters: DataFilterRow[] = [];

        if (tempFilters) {
            newFilters = [...tempFilters];
        }

        const idx = newFilters.findIndex(x => x.fieldName === filter.fieldName);

        if (idx !== -1) {
            if (filter.filterValues || filter.filterDates || filter.filterNumbers) {
                newFilters[idx].filterValues = filter.filterValues;
                newFilters[idx].filterDates = filter.filterDates;
                newFilters[idx].filterNumbers = filter.filterNumbers;
                changed = true;
            } else {
                newFilters.splice(idx, 1);
                changed = true;
            }
        } else {
            if (filter.filterValues || filter.filterDates || filter.filterNumbers) {
                newFilters.push({
                    fieldName: filter.fieldName,
                    filterValues: filter.filterValues,
                    filterDates: filter.filterDates,
                    filterNumbers: filter.filterNumbers,
                });
                changed = true;
            }
        }

        if (changed) {
            setTempFilters(newFilters);
            // console.log('onFilterChanged', filter);
        }
    }

    const iconsBodyTemplate = () => {
        return (
            <div className="p-grid p-align-center" style={{ width: '100%' }}>
                <div className="p-col">
                    <h2>Filters</h2>
                </div>
            </div>
        )
    }

    return (
        <Sidebar visible={filterSidebarVisible} position="right" blockScroll={true} icons={iconsBodyTemplate}
            onHide={() => setFilterSidebarVisible(false)}>
            <ScrollPanel style={{ width: '100%', height: size.height - 150 }} className="custom p-pt-3">
                <div className="p-fluid">
                    {
                        fields && fields.map((field, index) =>
                            <div key={index.toString()} className="p-field">
                                <FilterField field={field} filter={tempFilters?.find(x => x.fieldName === field.fieldName)} onFilterChanged={onFilterChanged} />
                            </div>
                        )
                    }
                </div>
            </ScrollPanel>
            <div className="p-grid p-justify-end p-pt-4 p-pr-3">
                <div className="p-col p-p-0 p-ml-1">
                    <Button label="Clear" icon="pi pi-filter-slash p-button-warning" className="p-button-warning" onClick={() => onFilterClear()} />
                </div>
                <div className="p-col-0">
                    <Button label="Apply" icon="pi pi-check p-button-success" className="p-button-success" onClick={() => onFilterApply()} />
                </div>
                <div className="p-col-0 p-pl-3">
                    <Button label="Cancel" icon="pi pi-times p-button-danger" className="p-button-danger" onClick={() => onFilterCancel()} />
                </div>
            </div>
        </Sidebar>
    )
}

export default FiltersComponent;