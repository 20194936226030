import { ProgressBar } from "primereact/progressbar";
import { useEffect, useState } from "react";
import { fillLabelsRowFromName, getChartData } from "../../../Helpers/Utility";
import { CHART_TYPE_GENDER, CHART_TYPE_JOB_GROUP_LEVEL_1_ALL_BY_GENDER, CHART_TYPE_JOB_GROUP_LEVEL_1_BY_GENDER, CHART_TYPE_MANAGEMENT_LEVEL_ALL_BY_GENDER, CHART_TYPE_MANAGEMENT_LEVEL_TERMS_BY_GENDER, CHART_TYPE_TERM_TYPE_BY_GENDER, DASHBOARD_AREA_TERM_GENDER as DASHBOARD_AREA_TERMS_GENDER, LABEL_FEMALE, LABEL_MALE } from "../../../Models/Constants";
import { getDashboardData } from "../../../Services/Api";
import BarChartStacked from "../../BarChartStacked/BarChartStacked";
import './DashboardTermsGenderControl.css';

type DashboardTermsGenderControlProps = {
    selectedDashboard: DashboardRow | undefined;
    selectedYears: CountRow[] | undefined;
    showCounts: boolean;
    showLogarithmicScale: boolean;
    filters: DataFilterRow[] | undefined;
    fillFilterFieldValues: (filterFieldValuesData: any) => void;
    onClickChartElement: (data: ChartClickEventData) => void;
}

const DashboardTermsGenderControl = ({ selectedDashboard, selectedYears, showCounts, showLogarithmicScale, filters, fillFilterFieldValues, onClickChartElement }: DashboardTermsGenderControlProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [chartDataByGender, setChartDataByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataTermTypeByGender, setChartDataTermTypeByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataManagementLevelAllByGender, setChartDataManagementLevelAllByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataManagementLevelTermsByGender, setChartDataManagementLevelTermsByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataJobGroupLevel1AllByGender, setChartDataJobGroupLevel1AllByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataJobGroupLevel1TermsByGender, setChartDataJobGroupLevel1TermsByGender] = useState<ChartDataMaster | undefined>(undefined);

    const fetchDashboardData = (dashboardId: number, years: string, filters: DataFilterRow[] | undefined) => {

        setLoading(true);

        getDashboardData(dashboardId, years, filters, DASHBOARD_AREA_TERMS_GENDER)
            .then((data: any) => {

                fillFilterFieldValues(data.data);

                // console.log('data', data);
                let countByGender = data.data.countByGender as CountRow[];
                let countTermTypeByGender = data.data.countTermTypeByGender as CountRow[];
                let countManagementLevelAllByGender = data.data.countManagementLevelAllByGender as CountRow[];
                let countManagementLevelTermsByGender = data.data.countManagementLevelTermsByGender as CountRow[];
                let countJobGroupLevel1AllByGender = data.data.countJobGroupLevel1AllByGender as CountRow[];
                let countJobGroupLevel1TermsByGender = data.data.countJobGroupLevel1TermsByGender as CountRow[];

                fillLabelsRowFromName(countByGender);
                fillLabelsRowFromName(countTermTypeByGender);
                fillLabelsRowFromName(countManagementLevelAllByGender);
                fillLabelsRowFromName(countManagementLevelTermsByGender);
                fillLabelsRowFromName(countJobGroupLevel1AllByGender);
                fillLabelsRowFromName(countJobGroupLevel1TermsByGender);

                // console.log('countByGender', countByGender);
                // console.log('countTermTypeByGender', countTermTypeByGender);

                const colorGender = ['#8C8D86', '#E6C069', '#897B61', '#8DAB8E', '#77A2BB', '#DF7689', '#4F504B'];

                setChartDataByGender(getChartData(countByGender, colorGender, false, false, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataTermTypeByGender(getChartData(countTermTypeByGender, colorGender, false, false, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataManagementLevelAllByGender(getChartData(countManagementLevelAllByGender, colorGender, false, false, true, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataManagementLevelTermsByGender(getChartData(countManagementLevelTermsByGender, colorGender, false, false, true, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataJobGroupLevel1AllByGender(getChartData(countJobGroupLevel1AllByGender, colorGender, false, false, true, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataJobGroupLevel1TermsByGender(getChartData(countJobGroupLevel1TermsByGender, colorGender, false, false, true, [LABEL_MALE, LABEL_FEMALE]));

                setLoading(false);
            })
            .catch((err: Error) => {
                console.log(err);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (selectedDashboard) {
            let years = '';

            if (selectedYears) {
                years = selectedYears.map(y => y.name).join(',');
            }

            fetchDashboardData(selectedDashboard.dashboardId, years, filters);
        }
    }, [selectedYears, filters])

    return (
        loading ?
            <div className="p-d-flex p-jc-center">
                <div className="p-d-flex p-flex-column p-pt-3">
                    <h2 className="p-m-2 p-p-2 p-as-center">Loading...</h2>
                    <ProgressBar mode="indeterminate" style={{ width: '500px' }} />
                </div>
            </div>
            :
            <div id="chartContainer" className="p-grid p-dir-col">
                <div className="p-col p-grid">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Active/Leave vrs Terms  - Gender"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataByGender}
                            position='bottom'
                            height="100px"
                            chartTypeId={CHART_TYPE_GENDER}
                            groupByFieldName="gender"
                            fieldName="Total"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Term Type"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataTermTypeByGender}
                            position='bottom'
                            height="100px"
                            chartTypeId={CHART_TYPE_TERM_TYPE_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="terminationType"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>

                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Active/Leave Managerial Level"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataManagementLevelAllByGender}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_MANAGEMENT_LEVEL_ALL_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="managementLevel"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Terms - Managerial level"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataManagementLevelTermsByGender}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_MANAGEMENT_LEVEL_TERMS_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="managementLevel"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>
                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Job Group Level 1 - Active/Leave"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataJobGroupLevel1AllByGender}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_JOB_GROUP_LEVEL_1_ALL_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="jobGroupLevel1"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Job Group Level 1 - Terminations"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataJobGroupLevel1TermsByGender}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_JOB_GROUP_LEVEL_1_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="jobGroupLevel1"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>

            </div >
    )
}

export default DashboardTermsGenderControl;
