import { Dialog } from "primereact/dialog";
import React, { Dispatch, SetStateAction } from "react";
import { FileUpload } from 'primereact/fileupload';
import { MAX_UPLOAD_SIZE_MB } from '../../../Models/Constants';

type FileUploadModalProps = {
    display: boolean;
    setDisplay: Dispatch<SetStateAction<boolean>>;
    confirm: (files: File[]) => void;
}

const FileUploadModal = ({ display, setDisplay, confirm }: FileUploadModalProps) => {
    return (
        <Dialog header="Load Dashboard Data (Tab Delimited File *.txt)" visible={display} modal style={{ width: '500px' }} onHide={() => setDisplay(false)}>
            <div className="confirmation-content">
                <FileUpload name="attachFileUpload[]" mode="basic" auto maxFileSize={MAX_UPLOAD_SIZE_MB * 1024 * 1024} accept="text/plain" customUpload uploadHandler={(e) => confirm(e.files as File[])} />
            </div>
        </Dialog>
    )
}

export default FileUploadModal;