
import React from 'react';
import { Message } from 'primereact/message';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Button } from 'primereact/components/button/Button';

type LoginProps = {
  firebaseUser: firebase.User | null
  signOut: any
}

function Login({ firebaseUser, signOut }: LoginProps) {

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,

    }
  } as firebaseui.auth.Config

  return (
    !firebaseUser ?
      <div className="p-d-flex p-jc-center">
        <div className="p-d-flex p-flex-column p-pt-3">
          <img src='logo512.png' alt='logo' className='p-as-center' style={{ width: '250px', height: '250px' }} />
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      </div >
      :
      <div>
        <div className="p-d-flex p-jc-center">
          <div className="p-d-flex p-flex-column p-pt-3">
            <Message className="p-m-2 p-p-2 p-as-center" severity="warn" text="You don't have access right. Please contact the administrator" />
            <Button type="button" className="p-button-warning p-as-center p-mt-3" icon="pi pi-external-link"
              iconPos="right" label="Sign out" style={{ width: "200px" }} onClick={() => signOut()}></Button>
          </div>
        </div>
      </div>
  );
}

export default Login;