import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'primereact/chart';
import React from 'react';
import { getToFixedDecimals } from '../../Helpers/Utility';
import { CHART_FONT_COLOR } from '../../Models/Constants';
import './BarChart.css';

type BarChartProps = {
    label: string;
    showCounts: boolean;
    showLogarithmicScale: boolean;
    withinEach: boolean;
    customTotals?: boolean | false;
    chartDataMaster?: ChartDataMaster;
    position?: string;
    height?: string;
    rotation?: number | 0;
    chartTypeId: number;
    fieldName: string;
    groupByFieldName: string;
    onClickElement: (data: ChartClickEventData) => void;
}

const BarChart = ({ label, showCounts, showLogarithmicScale, withinEach, customTotals, chartDataMaster, position, height, rotation, chartTypeId, fieldName, groupByFieldName, onClickElement }: BarChartProps) => {
    const sumReducer = (acc: number, value: number) => acc + value;

    let total = 0;

    if (chartDataMaster) {
        if (!withinEach)
            chartDataMaster.data.forEach(x => total += x.data.reduce(sumReducer));
    }

    const getLabel = (groupBy: string, value: number, dataIndex: number) => {
        if (chartDataMaster) {
            // console.log({ value, showCounts, withinEach, context });

            if (showCounts) {
                return value.toLocaleString('en-US');
            }
            else {
                if (withinEach) {
                    if (showCounts) {
                        return value.toLocaleString('en-US');
                    } else {
                        const percentage = value * 100;
                        return percentage.toFixed(getToFixedDecimals(percentage)) + '%';
                    }
                } else {

                    if (customTotals) {
                        // console.log(label, chartDataMaster.data);
                        let totRow = chartDataMaster.data.find(x => x.groupBy === groupBy);

                        if (totRow) {
                            value = totRow.data[dataIndex];
                            total = totRow.totals[dataIndex];
                            // console.log(label, total);
                        }
                    }

                    // console.log(label, (value / total * 100).toFixed(0) + '%');

                    const percentage = value / total * 100;
                    return percentage.toFixed(getToFixedDecimals(percentage)) + '%';
                }
            }
        } else {
            return '';
        }
    }

    // console.log('chartDataMaster.data', chartDataMaster?.data);

    let basicOptions = {
        plugins: [ChartDataLabels],
        responsive: true,
        maintainAspectRatio: false,
        onHover: function (evt: any, items: any) {
            evt.target.style.cursor = items[0] ? 'pointer' : 'default';
        },
        onClick: function (evt: any, items: any) {
            // console.log('legend onClick', evt);
            // console.log('legd item', items);

            if (items && items.length == 2) {
                const chart = items[0]._chart;
                const elements = chart.getElementAtEvent(evt);

                // console.log('elements', elements);

                if (elements && elements.length > 0) {
                    const element = elements[0];

                    // console.log('element', element);

                    onClickElement({
                        chartTypeId: chartTypeId,
                        fieldName: fieldName,
                        fieldValue: element._model.label,
                        groupByFieldName: groupByFieldName,
                        groypByFieldValue: element._model.datasetLabel,
                    } as ChartClickEventData);
                }
            }
        },
        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    if (customTotals && !showCounts) {
                        let value: number = tooltipItem.value;
                        return (value * 100).toFixed(0) + '%';
                    } else {
                        let value: number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return getLabel(tooltipItem.label, value, tooltipItem.index);
                    }
                }
            }
        },
        legend: {
            position: position || 'right',
            labels: {
                fontColor: CHART_FONT_COLOR
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: CHART_FONT_COLOR,
                },
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                type: showLogarithmicScale ? 'logarithmic' : 'linear',
                ticks: {
                    display: false,
                    suggestedMin: 0,
                },
                gridLines: {
                    display: false
                }
            }]
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 0
            }
        }
    };

    const basicData = {
        labels: chartDataMaster?.labels,
        datasets: chartDataMaster?.data?.map(d => {
            let newData: number[] = [];

            if (withinEach) {
                if (showCounts) {
                    newData = d.data;
                } else {
                    if (customTotals) {
                        d.data.forEach((item, idx) => {
                            newData.push(item / d.totals[idx]);
                        });
                    } else {
                        d.data.forEach((item, idx) => {
                            let currentTotal = 0;
                            chartDataMaster.data.forEach(cdm => currentTotal += cdm.data[idx]);
                            newData.push(item / currentTotal);
                        });
                    }
                }
            } else {
                if (showCounts) {
                    newData = d.data;
                } else {
                    if (customTotals) {
                        // console.log('d', d);
                        d.data.forEach((item, idx) => {
                            newData.push(item / d.totals[idx]);
                        });
                    } else {
                        newData = d.data;
                    }
                }
            }

            return {
                label: d.groupBy,
                backgroundColor: d.color,
                data: newData,
                datalabels: {
                    color: CHART_FONT_COLOR,
                    align: 'end',
                    anchor: 'end',
                    offset: 5,
                    rotation: rotation,
                    formatter: (value: number, context: any) => getLabel(d.groupBy, value, context.dataIndex),
                    display: 'auto',
                    listeners: {
                        click: function (context: any) {
                            // console.log(context);

                            onClickElement({
                                chartTypeId: chartTypeId,
                                fieldName: fieldName,
                                fieldValue: context.chart.config.data.labels[context.dataIndex],
                                groupByFieldName: groupByFieldName,
                                groypByFieldValue: context.dataset.label,
                            } as ChartClickEventData);
                        }
                    }
                }
            };
        }),
    };

    // if (label === 'Attrition Rate Trend by Ethnicity')
    //     console.log(basicData);

    return (
        <div>
            <div className="p-text-bold p-pt-1 p-pb-1 p-pl-3">{label}</div>
            <div>
                <Chart type="bar" data={basicData} options={basicOptions} height={height || "100px"} />
            </div>
        </div>
    )
}

export default BarChart;