import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Dispatch, SetStateAction } from "react";
import './AlertModal.css';

type AlertModalProps = {
    displayAlert: boolean;
    setDisplayAlert: Dispatch<SetStateAction<boolean>>;
    title: string;
    message: string;
}

const AlertModal = ({ displayAlert, setDisplayAlert, title, message }: AlertModalProps) => {
    const renderConfirmationFooter = () => {
        return (
            <div>
                <Button label="Ok" icon="pi pi-times" onClick={() => setDisplayAlert(false)} className="p-button-text" />
            </div>
        );
    }

    return (
        <Dialog header={title} visible={displayAlert} modal style={{ width: '600px' }} footer={renderConfirmationFooter()} onHide={() => setDisplayAlert(false)}>
            <div className="confirmation-content">
                <i className="pi pi-ban pi-red p-mr-3" style={{ fontSize: '2rem' }} />
                <span style={{ verticalAlign: 'super' }}>{message}</span>
            </div>
        </Dialog>
    )
}

export default AlertModal;