import { v4 as uuidv4 } from 'uuid';
import { LABEL_BLANK } from '../Models/Constants';

export const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * Math.floor(max - min)) + Math.floor(min);
}

export const createEmptyPerson = (sessionId: string, isSurveyPerson: number): SessionPerson => {
    return {
        sessionPersonId: 0,
        sessionId: sessionId,
        sessionGroupId: 0,
        isSurveyPerson: isSurveyPerson,
        name: '',
        gender: '',
        ethnicity: '',
        age: '',
        sexualOrientation: '',
        religion: '',
        levelOfEducation: '',
        disability: '',
        military: '',
        maritalStatus: '',
        createdOn: new Date(),
        trustedPeople: [],
    } as SessionPerson;
}

export const getWizardDataAsSingleArray = (data: WizardData) => {
    let people = [];

    data.person.isSurveyPerson = 1;

    people.push(data.person);

    for (let i = 0; i < data.trustedPeople.length; i++) {
        if (data.trustedPeople[i].name !== '')
            people.push(data.trustedPeople[i]);
    }

    // console.log('people', people);

    return people;
}

export const getSampleData = (wizardSteps: WizardStep[] | undefined): WizardData => {
    let sessionId = uuidv4();

    let data = {
        person: createEmptyPerson(sessionId, 1),
        trustedPeople: [
        ]
    } as WizardData

    let names = ['Gabriele', 'Sandra', 'Derrick', 'Jim', 'Jake', 'Milly'];

    data.person.name = names[getRandomInt(0, names.length)];

    let nTrustedPeople = getRandomInt(2, 5);

    for (let i = 0; i < nTrustedPeople; i++) {
        let trustedPerson = createEmptyPerson(sessionId, 0);
        trustedPerson.name = names[getRandomInt(0, names.length)];
        data.trustedPeople.push(trustedPerson);
    }

    let people = getWizardDataAsSingleArray(data);

    if (wizardSteps) {
        for (let t = 0; t < people.length; t++) {
            let p = people[t] as any;

            for (let i = 0; i < wizardSteps.length; i++) {
                let ws = wizardSteps[i];

                if (!ws.multiple) {
                    if (ws.propertyName !== 'name') {
                        p[ws.propertyName] = ws.listValues[getRandomInt(0, ws.listValues.length)];
                    }
                }
            }
        }
    }

    return data;
}

export const sumReducer = (acc: number, value: number) => acc + value;

export const getRandomColors = (nColors: number): string[] => {
    let colorScheme = [
        "#25CCF7", "#FD7272", "#54a0ff", "#00d2d3",
        "#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e",
        "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50",
        "#f1c40f", "#e67e22", "#e74c3c", "#ecf0f1", "#95a5a6",
        "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d",
        "#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9",
        "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#ffeaa7",
        "#fab1a0", "#ff7675", "#fd79a8", "#fdcb6e", "#e17055",
        "#d63031", "#feca57", "#5f27cd", "#54a0ff", "#01a3a4"
    ];

    let colors: string[] = [];

    for (let i = 0; i < nColors; i++) {
        let idx = getRandomInt(0, colorScheme.length);
        colors.push(colorScheme[i]);
        colorScheme.splice(idx, 1);
    }

    return colors;
}

export const distinctArrayString = (array: string[]): string[] => {
    return array.filter((value: string, index: number, self: string[]) => {
        return self.indexOf(value) === index;
    });
}

export const swapNames = (countRows: CountRow[]): CountRow[] => {
    let newCountRows: CountRow[] = [];

    countRows.forEach(x => newCountRows.push({
        label: x.label2,
        name: x.name2,
        label2: x.label,
        name2: x.name,
        rows: x.rows,
        total: x.total,
    }))

    return newCountRows;
}

export const fillLabelsRowFromName = (countRows: CountRow[]) => {
    countRows.forEach(cr => cr.label = `${cr.name ? cr.name : LABEL_BLANK}`);
    countRows.forEach(cr => cr.label2 = `${cr.name2 ? cr.name2 : LABEL_BLANK}`);
}

export const getToFixedDecimals = (value: number): number => {
    if (value > 0 && value < 1)
        return 2;
    else
        return 0;
}

export const getChartData = (countRows: CountRow[], colors: string[], sortByCount: boolean, sortDesc: boolean, customTotals: boolean, sortFields?: string[]): ChartDataMaster => {

    let groupByCounts: GroupByCount[] = [];

    for (const c of countRows) {
        let existingC = groupByCounts.find(x => x.groupBy === c.label);

        if (existingC) {
            existingC.count += c.rows;
        } else {
            groupByCounts.push({
                groupBy: c.label,
                count: c.rows,
            })
        }
    }

    if (sortByCount) {
        // console.log('groupByCounts', groupByCounts);
        groupByCounts = groupByCounts.sort((a, b) => b.count - a.count);
    } else {
        if (sortDesc)
            groupByCounts.sort((a, b) => b.groupBy.toString().localeCompare(a.groupBy));
        else
            groupByCounts.sort((a, b) => a.groupBy.toString().localeCompare(b.groupBy));
    }

    const idxBlank = groupByCounts.findIndex(x => x.groupBy === LABEL_BLANK);

    if (idxBlank !== -1) {
        // Move Blank at the end
        groupByCounts.push(groupByCounts.splice(idxBlank, 1)[0]);
    }

    // console.log('groupByCounts', groupByCounts);

    let data: ChartDataDetail[] = [];

    let groupBys2 = distinctArrayString(countRows.map(x => x.label2));

    // console.log('groupBys2', groupBys2);

    if (sortFields) {
        groupBys2.sort((a, b) => {
            let nA = sortFields.indexOf(a);
            let nB = sortFields.indexOf(b);

            return nA < nB ? -1 : 1;
        });
    } else if (sortByCount) {
        groupBys2.sort((a, b) => {
            let aTotal = countRows.filter(x => x.label2 === a).map(x => x.rows).reduce(sumReducer);
            let bTotal = countRows.filter(x => x.label2 === b).map(x => x.rows).reduce(sumReducer);

            return bTotal - aTotal;
        });
    }
    else {
        groupBys2.sort((a, b) => a.toString().localeCompare(b));
    }

    for (let i = 0; i < groupBys2.length; i++) {
        let groupBy2 = groupBys2[i];

        let dataRows: number[] = [];
        let dataTotals: number[] = [];

        for (let j = 0; j < groupByCounts.length; j++) {
            let groupBy = groupByCounts[j];

            let countRow = countRows.find(x => x.label === groupBy.groupBy && x.label2 === groupBy2);
            let rows = countRow?.rows || 0;
            let total = countRows.filter(x => x.label2 === groupBy2).map(x => x.rows).reduce(sumReducer);

            if (customTotals)
                total = countRow?.total || 0;

            dataRows.push(rows);
            dataTotals.push(total);
        }

        let d = {
            groupBy: groupBy2,
            color: colors[i],
            data: dataRows,
            totals: dataTotals,
        } as ChartDataDetail;

        data.push(d);
    }

    // console.log('labels', labels);

    let newChartData =
        {
            labels: groupByCounts.map(x => x.groupBy),
            data: data,
        } as ChartDataMaster

    return newChartData;
}
