import { ProgressBar } from "primereact/progressbar";
import { useEffect, useState } from "react";
import { fillLabelsRowFromName, getChartData } from "../../../Helpers/Utility";
import { CHART_TYPE_AGE_BAND, CHART_TYPE_GENDER, CHART_TYPE_JOB_GROUP_LEVEL_1_BY_GENDER, CHART_TYPE_JOB_GROUP_LEVEL_2_BY_GENDER, CHART_TYPE_MANAGEMENT_LEVEL_BY_GENDER, CHART_TYPE_YOY_HEAD_COUNT_TREND_BY_GENDER, COLOR_FEMALE, COLOR_MALE, DASHBOARD_AREA_GENDER, LABEL_FEMALE, LABEL_MALE } from "../../../Models/Constants";
import { getDashboardData } from "../../../Services/Api";
import BarChart from "../../BarChart/BarChart";
import BarChartStacked from "../../BarChartStacked/BarChartStacked";
import ChartPeople from "../../ChartPeople/ChartPeople";
import './DashboardGenderControl.css';

type DashboardGenderControlProps = {
    selectedDashboard: DashboardRow | undefined;
    selectedYears: CountRow[] | undefined;
    showCounts: boolean;
    showLogarithmicScale: boolean;
    filters: DataFilterRow[] | undefined;
    fillFilterFieldValues: (filterFieldValuesData: any) => void;
    onClickChartElement: (data: ChartClickEventData) => void;
}

const DashboardGenderControl = ({ selectedDashboard, selectedYears, showCounts, showLogarithmicScale, filters, fillFilterFieldValues, onClickChartElement }: DashboardGenderControlProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [chartDataByGender, setChartDataByGender] = useState<ChartData[]>([]);
    const [chartDataAgeBandByGender, setChartDataAgeBandByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataJobGroupLevel1ByGender, setChartDataJobGroupLevel1ByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataManagementLevelByGender, setChartDataManagementLevelByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataJobGroupLevel2ByGender, setChartDataJobGroupLevel2ByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataYoyHeadCountTrendByGender, setChartDataYoyHeadCountTrendByGender] = useState<ChartDataMaster | undefined>(undefined);

    const fetchDashboardData = (dashboardId: number, years: string, filters: DataFilterRow[] | undefined) => {

        setLoading(true);

        getDashboardData(dashboardId, years, filters, DASHBOARD_AREA_GENDER)
            .then((data: any) => {

                fillFilterFieldValues(data.data);

                // console.log('data', data);
                let countByGender = data.data.countByGender as CountRow[];
                let countByAgeBand = data.data.countByAgeBand as CountRow[];
                let countJobGroupLevel1ByGender = data.data.countJobGroupLevel1ByGender as CountRow[];
                let countManagementLevelByGender = data.data.countManagementLevelByGender as CountRow[];
                let countJobGroupLevel2ByGender = data.data.countJobGroupLevel2ByGender as CountRow[];
                let countYoyHeadCountTrendByGender = data.data.countYoyHeadCountTrendByGender as CountRow[];

                fillLabelsRowFromName(countByGender);
                fillLabelsRowFromName(countByAgeBand);
                fillLabelsRowFromName(countJobGroupLevel1ByGender);
                fillLabelsRowFromName(countManagementLevelByGender);
                fillLabelsRowFromName(countJobGroupLevel2ByGender);
                fillLabelsRowFromName(countYoyHeadCountTrendByGender);

                const colorGender = ['#8C8D86', '#E6C069', '#897B61', '#8DAB8E', '#77A2BB', '#DF7689', '#4F504B'];

                fillChartDataByGender(countByGender);
                setChartDataAgeBandByGender(getChartData(countByAgeBand, colorGender, false, false, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataJobGroupLevel1ByGender(getChartData(countJobGroupLevel1ByGender, colorGender, true, false, true, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataManagementLevelByGender(getChartData(countManagementLevelByGender, colorGender, true, false, true, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataJobGroupLevel2ByGender(getChartData(countJobGroupLevel2ByGender, colorGender, true, false, true, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataYoyHeadCountTrendByGender(getChartData(countYoyHeadCountTrendByGender, colorGender, true, true, false, [LABEL_MALE, LABEL_FEMALE]));

                setLoading(false);
            })
            .catch((err: Error) => {
                console.log(err);
                setLoading(false);
            });
    }

    const fillChartDataByGender = (countByGenders: CountRow[]) => {
        let newChartDataByGender = countByGenders.map(x => {
            return {
                groupBy: x.name,
                color: x.name === LABEL_MALE ? COLOR_MALE : COLOR_FEMALE,
                data: x.rows,
            } as ChartData
        });

        // console.log('newChartDataByGender', newChartDataByGender);

        setChartDataByGender(newChartDataByGender);
    }

    useEffect(() => {
        if (selectedDashboard) {
            let years = '';

            if (selectedYears) {
                years = selectedYears.map(y => y.name).join(',');
            }

            fetchDashboardData(selectedDashboard.dashboardId, years, filters);
        }
    }, [selectedYears, filters])

    return (
        loading ?
            <div className="p-d-flex p-jc-center">
                <div className="p-d-flex p-flex-column p-pt-3">
                    <h2 className="p-m-2 p-p-2 p-as-center">Loading...</h2>
                    <ProgressBar mode="indeterminate" style={{ width: '500px' }} />
                </div>
            </div>
            :
            <div id="chartContainer" className="p-grid p-dir-col">
                <div className="p-col p-grid">
                    <div className="p-col-2 chart-block">
                        <ChartPeople totalLabel="Total HC"
                            chartData={chartDataByGender}
                            chartTypeId={CHART_TYPE_GENDER}
                            groupByFieldName="gender"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-5 chart-block">
                        <BarChart label="Age Band - % of Total"
                            showCounts={showCounts}
                            showLogarithmicScale={showLogarithmicScale}
                            withinEach={false}
                            chartDataMaster={chartDataAgeBandByGender}
                            chartTypeId={CHART_TYPE_AGE_BAND}
                            groupByFieldName="gender"
                            fieldName="subGenerationName"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-5 chart-block">
                        <BarChart label="Age Band - % within each"
                            showCounts={showCounts}
                            showLogarithmicScale={showLogarithmicScale}
                            withinEach={true}
                            chartDataMaster={chartDataAgeBandByGender}
                            chartTypeId={CHART_TYPE_AGE_BAND}
                            groupByFieldName="gender"
                            fieldName="subGenerationName"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>

                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Job Group Level 1"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataJobGroupLevel1ByGender}
                            position='bottom'
                            height="200px"
                            chartTypeId={CHART_TYPE_JOB_GROUP_LEVEL_1_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="jobGroupLevel1"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Management Level"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataManagementLevelByGender}
                            position='bottom'
                            height="200px"
                            chartTypeId={CHART_TYPE_MANAGEMENT_LEVEL_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="managementLevel"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>
                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Job Group Level 2"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataJobGroupLevel2ByGender}
                            position='bottom'
                            height="400px"
                            chartTypeId={CHART_TYPE_JOB_GROUP_LEVEL_2_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="jobGroupLevel2"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="YoY Headcount Trend"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataYoyHeadCountTrendByGender}
                            position='bottom'
                            height="400px"
                            chartTypeId={CHART_TYPE_YOY_HEAD_COUNT_TREND_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="ye"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>

            </div >
    )
}

export default DashboardGenderControl;
