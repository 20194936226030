import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyD1B5Ah6eosw_s9WaTUNOwm_8jiWFT_UzI",
    authDomain: "trusted-5.firebaseapp.com",
    databaseURL: "https://trusted-5.firebaseio.com",
    projectId: "trusted-5",
    storageBucket: "trusted-5.appspot.com",
    messagingSenderId: "101876599316",
    appId: "1:101876599316:web:14ae76654c167ab07ff501",
    measurementId: "G-Y55N4MJTWQ"
};

firebase.initializeApp(firebaseConfig);