import { ProgressBar } from "primereact/progressbar";
import { useEffect, useState } from "react";
import { fillLabelsRowFromName, getChartData } from "../../../Helpers/Utility";
import { CHART_TYPE_ETHNICITY, CHART_TYPE_JOB_GROUP_LEVEL_1_ALL_BY_ETHNICITY, CHART_TYPE_JOB_GROUP_LEVEL_1_TERMS_BY_ETHNICITY, CHART_TYPE_MANAGEMENT_LEVEL_ALL_BY_ETHNICITY, CHART_TYPE_MANAGEMENT_LEVEL_BY_ETHNICITY, CHART_TYPE_TERM_TYPE_BY_ETHNICITY, DASHBOARD_AREA_TERM_ETHNICITY } from "../../../Models/Constants";
import { getDashboardData } from "../../../Services/Api";
import BarChartStacked from "../../BarChartStacked/BarChartStacked";
import './DashboardTermsEthnicityControl.css';

type DashboardTermsEthnicityControlProps = {
    selectedDashboard: DashboardRow | undefined;
    selectedYears: CountRow[] | undefined;
    showCounts: boolean;
    showLogarithmicScale: boolean;
    filters: DataFilterRow[] | undefined;
    fillFilterFieldValues: (filterFieldValuesData: any) => void;
    onClickChartElement: (data: ChartClickEventData) => void;
}

const DashboardTermsEthnicityControl = ({ selectedDashboard, selectedYears, showCounts, showLogarithmicScale, filters, fillFilterFieldValues, onClickChartElement }: DashboardTermsEthnicityControlProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [chartDataByEthnicity, setChartDataByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataTermTypeByEthnicity, setChartDataTermTypeByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataManagementLevelAllByEthnicity, setChartDataManagementLevelAllByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataManagementLevelTermsByEthnicity, setChartDataManagementLevelTermsByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataJobGroupLevel1AllByEthnicity, setChartDataJobGroupLevel1AllByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataJobGroupLevel1TermsByEthnicity, setChartDataJobGroupLevel1TermsByEthnicity] = useState<ChartDataMaster | undefined>(undefined);

    const fetchDashboardData = (dashboardId: number, years: string, filters: DataFilterRow[] | undefined) => {

        setLoading(true);

        getDashboardData(dashboardId, years, filters, DASHBOARD_AREA_TERM_ETHNICITY)
            .then((data: any) => {

                fillFilterFieldValues(data.data);

                // console.log('data', data);
                let countByEthnicity = data.data.countByEthnicity as CountRow[];
                let countTermTypeByEthnicity = data.data.countTermTypeByEthnicity as CountRow[];
                let countManagementLevelAllByEthnicity = data.data.countManagementLevelAllByEthnicity as CountRow[];
                let countManagementLevelTermsByEthnicity = data.data.countManagementLevelTermsByEthnicity as CountRow[];
                let countJobGroupLevel1AllByEthnicity = data.data.countJobGroupLevel1AllByEthnicity as CountRow[];
                let countJobGroupLevel1TermsByEthnicity = data.data.countJobGroupLevel1TermsByEthnicity as CountRow[];

                fillLabelsRowFromName(countByEthnicity);
                fillLabelsRowFromName(countTermTypeByEthnicity);
                fillLabelsRowFromName(countManagementLevelAllByEthnicity);
                fillLabelsRowFromName(countManagementLevelTermsByEthnicity);
                fillLabelsRowFromName(countJobGroupLevel1AllByEthnicity);
                fillLabelsRowFromName(countJobGroupLevel1TermsByEthnicity);

                // console.log('countByEthnicity', countByEthnicity);
                // console.log('countTermTypeByEthnicity', countTermTypeByEthnicity);

                const colorEthnicity = ['#8C8D86', '#E6C069', '#897B61', '#8DAB8E', '#77A2BB', '#DF7689', '#4F504B'];

                setChartDataByEthnicity(getChartData(countByEthnicity, colorEthnicity, true, false, false));
                setChartDataTermTypeByEthnicity(getChartData(countTermTypeByEthnicity, colorEthnicity, true, false, false));
                setChartDataManagementLevelAllByEthnicity(getChartData(countManagementLevelAllByEthnicity, colorEthnicity, true, false, true));
                setChartDataManagementLevelTermsByEthnicity(getChartData(countManagementLevelTermsByEthnicity, colorEthnicity, true, false, true));
                setChartDataJobGroupLevel1AllByEthnicity(getChartData(countJobGroupLevel1AllByEthnicity, colorEthnicity, true, false, true));
                setChartDataJobGroupLevel1TermsByEthnicity(getChartData(countJobGroupLevel1TermsByEthnicity, colorEthnicity, true, false, true));

                setLoading(false);
            })
            .catch((err: Error) => {
                console.log(err);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (selectedDashboard) {
            let years = '';

            if (selectedYears) {
                years = selectedYears.map(y => y.name).join(',');
            }

            fetchDashboardData(selectedDashboard.dashboardId, years, filters);
        }
    }, [selectedYears, filters])

    return (
        loading ?
            <div className="p-d-flex p-jc-center">
                <div className="p-d-flex p-flex-column p-pt-3">
                    <h2 className="p-m-2 p-p-2 p-as-center">Loading...</h2>
                    <ProgressBar mode="indeterminate" style={{ width: '500px' }} />
                </div>
            </div>
            :
            <div id="chartContainer" className="p-grid p-dir-col">
                <div className="p-col p-grid">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Active/Leave vrs Terms  - Ethnicity"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataByEthnicity}
                            position='bottom'
                            height="100px"
                            chartTypeId={CHART_TYPE_ETHNICITY}
                            groupByFieldName="ethnicity"
                            fieldName="Total"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Term Type"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataTermTypeByEthnicity}
                            position='bottom'
                            height="100px"
                            chartTypeId={CHART_TYPE_TERM_TYPE_BY_ETHNICITY}
                            groupByFieldName="ethnicity"
                            fieldName="terminationType"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>

                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Active/Leave Managerial Level"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataManagementLevelAllByEthnicity}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_MANAGEMENT_LEVEL_ALL_BY_ETHNICITY}
                            groupByFieldName="ethnicity"
                            fieldName="managementLevel"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Terms - Managerial level"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataManagementLevelTermsByEthnicity}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_MANAGEMENT_LEVEL_BY_ETHNICITY}
                            groupByFieldName="ethnicity"
                            fieldName="managementLevel"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>
                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Job Group Level 1 - Active/Leave"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataJobGroupLevel1AllByEthnicity}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_JOB_GROUP_LEVEL_1_ALL_BY_ETHNICITY}
                            groupByFieldName="ethnicity"
                            fieldName="jobGroupLevel1"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-6 chart-block">
                        <BarChartStacked label="Job Group Level 1 - Terminations"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataJobGroupLevel1TermsByEthnicity}
                            position='bottom'
                            height="300px"
                            chartTypeId={CHART_TYPE_JOB_GROUP_LEVEL_1_TERMS_BY_ETHNICITY}
                            groupByFieldName="ethnicity"
                            fieldName="jobGroupLevel1"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>

            </div >
    )
}

export default DashboardTermsEthnicityControl;
