import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/components/inputtext/InputText";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import React, { useEffect, useState } from "react";
import { UPLOAD_HEADERS } from "../../Models/Constants";
import { createDashboard, deleteDashboard, getDashboards, updateDashboard, uploadData } from "../../Services/Api";
import AlertModal from "../Modals/AlertModal/AlertModal";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";
import FileUploadModal from "../Modals/FileUploadModal/FileUploadModal";
import './AdminDashboardsControl.css';

const AdminDashboardsControl = () => {
    const [dashboards, setDashboards] = useState<DashboardRow[] | undefined>()
    const [] = useState<SessionPerson[] | undefined>()
    const [editingDashboard, setEditingDashboard] = useState<DashboardRow | undefined>()
    const [fileUploadDisplay, setFileUploadDisplay] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
    const [displayConfirm, setDisplayConfirm] = useState<boolean>(false);
    const [displayAlertError, setDisplayAlertError] = useState<boolean>(false);
    const [displayAlertErrorMessage, setDisplayAlertErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    let files: any = null;

    const fetchDashboards = () => {
        getDashboards()
            .then((data: any) => {
                let dashboards = data.data as DashboardRow[];
                // console.log('dashboards', dashboards);

                setDashboards(dashboards);
            })
            .catch((err: Error) => console.log(err));
    }

    useEffect(() => {
        fetchDashboards();
    }, [])

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setShowEditDialog(false)} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => saveDashboardHandler()} />
        </React.Fragment>
    );

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let data = { ...editingDashboard } as any;
        data[`${name}`] = val;

        setEditingDashboard(data)
    }

    const openNew = () => {
        setEditingDashboard(undefined);
        setShowEditDialog(true);
    }

    const saveDashboardHandler = async () => {
        setShowEditDialog(false);
        setEditingDashboard(undefined);

        console.log('saveDashboardHandler', files);

        // console.log('editingDashboard', editingDashboard);
        // console.log('dashboards', dashboards);

        if (dashboards && editingDashboard) {
            if (editingDashboard.dashboardId) {
                await updateDashboard(editingDashboard).then(() => {
                    // let dashboard = data.data as DashboardRow;
                    // console.log('dashboard', dashboard);

                    let newDashboards = [...dashboards];

                    let index = newDashboards.findIndex(x => x.dashboardId === editingDashboard.dashboardId);
                    newDashboards[index] = editingDashboard;

                    setDashboards(newDashboards);
                    // console.log('newDashboards', newDashboards);
                })
                    .catch((err: Error) => console.log(err));
            } else {
                createDashboard(editingDashboard).then((data: any) => {
                    let dashboard = data.data as DashboardRow;
                    console.log('dashboard', dashboard);

                    let newDashboards = [...dashboards];

                    newDashboards.push(dashboard);

                    setDashboards(newDashboards);
                    console.log('newDashboards', newDashboards);
                })
                    .catch((err: Error) => console.log(err));
            }
        }
    }

    const deleteDashboardHandler = async () => {
        console.log('deleteDashboardHandler', editingDashboard, dashboards);
        if (editingDashboard && dashboards) {
            let newDashboards = dashboards.filter(sg => sg.dashboardId !== editingDashboard.dashboardId);
            setDashboards(newDashboards);
            setDisplayConfirm(false);
            setEditingDashboard(undefined);
            await deleteDashboard(editingDashboard);
        }
    }

    const rowsBodyTemplate = (dashboard: DashboardRow) => {
        return (
            <div>{dashboard.rows.toLocaleString('en-US')}</div>
        );
    }

    const actionBodyTemplate = (dashboard: DashboardRow) => {
        return (
            <React.Fragment>
                {
                    loading && editingDashboard?.dashboardId === dashboard.dashboardId ?
                        <ProgressBar mode="indeterminate" />
                        :
                        <div className='p-d-inline-flex p-flex-row '>
                            <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" disabled={editingDashboard !== undefined} onClick={() => editDashboard(dashboard)} />
                            <Button icon="pi pi-upload" className="p-button-rounded p-mr-2" disabled={editingDashboard !== undefined} onClick={() => showDashboardUpload(dashboard)} />
                            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" disabled={editingDashboard !== undefined} onClick={() => askDeleteDashboard(dashboard)} />
                        </div>
                }
            </React.Fragment>
        );
    }

    const editDashboard = (dashboard: DashboardRow) => {
        setEditingDashboard(dashboard);
        setShowEditDialog(true);
    }

    const askDeleteDashboard = (dashboard: DashboardRow) => {
        setEditingDashboard(dashboard);
        setDisplayConfirm(true);
    }

    const showDashboardUpload = (dashboard: DashboardRow) => {
        // console.log('showDashboardUpload', dashboard);
        setEditingDashboard(dashboard);
        setFileUploadDisplay(true);
    }

    const confirmUploadFile = (files: File[]) => {
        // console.log('confirmUploadFile', editingDashboard);

        setFileUploadDisplay(false);

        if (editingDashboard && files && files.length > 0) {
            setLoading(true);
            console.log('confirmUploadFile', files);

            var reader = new FileReader();
            reader.onload = async function (event) {
                // The file's text will be printed here
                if (event && event.target) {
                    const data = event.target.result as string;
                    const error = await validateData(data);

                    if (error === '') {
                        uploadData(editingDashboard.dashboardId, files[0])
                            .then((data) => {

                                if (data.status === 200) {
                                    setLoading(false);
                                    setEditingDashboard(undefined);
                                    fetchDashboards();
                                    console.log('done');
                                } else {
                                    console.log('error', data.data.error);
                                    setDisplayAlertErrorMessage(data.data.error);
                                    setLoading(false);
                                    setEditingDashboard(undefined);
                                    setDisplayAlertError(true);
                                    console.log('Error', data.data.error);
                                }
                            })
                            .catch((err: Error) => {
                                console.log('error', err.message);
                                setLoading(false);
                                setEditingDashboard(undefined);
                                setDisplayAlertErrorMessage(error);
                                setDisplayAlertError(true);
                            });
                    } else {
                        console.log('error', error);
                        setLoading(false);
                        setEditingDashboard(undefined);
                        setDisplayAlertErrorMessage(error);
                        setDisplayAlertError(true);
                    }
                }
            };

            reader.readAsText(files[0]);
        }
    }

    const validateData = async (data: string): Promise<string> => {
        let error: string = '';

        let lines = data.split('\r\n');

        if (lines.length > 0) {
            lines = lines.filter(x => x !== '');
        }

        // console.log('lines', lines);

        if (lines.length > 1) {
            let line = lines[0];

            const headers = line.split('\t');
            let headersRequired = [...UPLOAD_HEADERS];

            headersRequired = headersRequired.filter(x => headers.indexOf(x) === -1);

            if (headersRequired.length === 0) {
                for (let i = 1; i < lines.length; i++) {
                    line = lines[i];

                    const fields = line.split('\t');

                    if (fields.length !== UPLOAD_HEADERS.length) {
                        error = `The number of values in line ${i + 1} doesn't match with the expected one`;
                        break;
                    }
                }
            } else {
                error = `The following headers are missing in the file : \r\n${headersRequired.join(', ')}`;
            }
        } else {
            error = 'No rows in the file';
        }

        return error;
    }

    return (
        <div className="p-d-flex p-flex-column">
            <div className="p-d-flex p-p-3">
                <Button label="New" icon="pi pi-plus" className="p-button p-mr-2" onClick={openNew} />
            </div>

            <DataTable value={dashboards} dataKey="dashboardId"
                paginator rows={10}
                paginatorTemplate="PageLinks" >
                <Column field="name" header="Name" />
                <Column body={rowsBodyTemplate} header="Rows" />
                <Column body={actionBodyTemplate} style={{ width: '150px' }} ></Column>
            </DataTable>

            <ConfirmModal displayConfirm={displayConfirm} setDisplayConfirm={setDisplayConfirm} confirm={deleteDashboardHandler} />

            <AlertModal displayAlert={displayAlertError} setDisplayAlert={setDisplayAlertError} title="Error" message={displayAlertErrorMessage} />

            <FileUploadModal
                display={fileUploadDisplay} setDisplay={setFileUploadDisplay}
                confirm={confirmUploadFile} />

            <Dialog visible={showEditDialog} style={{ width: '800px' }} header="Edit Session Group" modal className="p-fluid" footer={editDialogFooter} onHide={() => setShowEditDialog(false)}>
                <div className="p-field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={editingDashboard?.name || ''} onChange={e => onInputChange(e, 'name')} autoComplete="off" autoFocus required />
                </div>
            </Dialog>
        </div >
    )

}

export default AdminDashboardsControl;