import './HeadCount.css';
import { Chart } from 'primereact/chart';
import { CHART_FONT_COLOR, CHART_MIN_PERCENTAGE_LABEL } from '../../Models/Constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getToFixedDecimals, sumReducer } from '../../Helpers/Utility';

type HeadCountProps = {
    label: string;
    showCounts: boolean;
    chartData: ChartData[];
    isPie?: boolean;
    position?: string;
    height?: string;
    chartTypeId: number;
    groupByFieldName: string;
    onClickElement: (data: ChartClickEventData) => void;
}

const HeadCount = ({ label, showCounts, chartData, isPie, position, height, chartTypeId, groupByFieldName, onClickElement }: HeadCountProps) => {

    let total = 0;

    if (chartData.length > 0)
        total = chartData.map(x => x.data).reduce(sumReducer);

    let basicOptions = {
        plugins: [ChartDataLabels],
        responsive: true,
        maintainAspectRatio: false,
        onHover: function (evt: any, items: any) {
            evt.target.style.cursor = items[0] ? 'pointer' : 'default';
        },
        onClick: function (evt: any, items: any) {
            // console.log('legend onClick', evt);
            // console.log('legd item', items);

            if (items && items.length == 1) {
                const chart = items[0]._chart;
                const elements = chart.getElementAtEvent(evt);

                // console.log('elements', elements);

                if (elements && elements.length > 0) {
                    const element = elements[0];

                    // console.log('element', element);

                    onClickElement({
                        chartTypeId: chartTypeId,
                        fieldName: '',
                        fieldValue: '',
                        groupByFieldName: groupByFieldName,
                        groypByFieldValue: element._model.label,
                    } as ChartClickEventData);
                }
            }
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            position: position || 'bottom',
            labels: {
                fontColor: CHART_FONT_COLOR
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    display: false,
                },
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                ticks: {
                    display: false,
                },
                gridLines: {
                    display: false
                }
            }]
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 20,
                bottom: 0
            }
        }
    };

    const basicData = {
        labels: chartData.map(x => x.groupBy),
        datasets: [
            {
                backgroundColor: chartData.map(x => x.color),
                data: chartData.map(x => x.data),
                datalabels: {
                    color: CHART_FONT_COLOR,
                    align: 'end',
                    anchor: 'end',
                    formatter: function (value: number) {
                        const percentage = (value / total * 100);

                        // if (percentage > CHART_MIN_PERCENTAGE_LABEL) {
                        if (showCounts) {
                            return value.toLocaleString('en-US');
                        }
                        else {
                            return percentage.toFixed(getToFixedDecimals(percentage)) + '%';
                        }
                        // } else {
                        //     return '';
                        // }
                    },
                    display: 'auto',
                    listeners: {
                        click: function (context: any) {
                            // console.log(context);

                            onClickElement({
                                chartTypeId: chartTypeId,
                                fieldName: '',
                                fieldValue: context.chart.config.data.labels[context.dataIndex],
                                groupByFieldName: groupByFieldName,
                                groypByFieldValue: context.dataset.label,
                            } as ChartClickEventData);
                        }
                    }
                }
            },
        ],
    };
    return (
        <div>
            <div className="p-text-bold p-text-center">{label}</div>
            <div>
                <Chart type={isPie ? 'pie' : 'doughnut'} data={basicData} options={basicOptions} height={height || "100px"} />
            </div>
        </div >
    )
}

export default HeadCount;