import { ProgressBar } from 'primereact/progressbar';
import { useEffect, useState } from "react";
import { fillLabelsRowFromName, getChartData, getRandomColors, swapNames } from "../../../Helpers/Utility";
import { CHART_TYPE_AGE_BAND, CHART_TYPE_ATTRITION_RATE_BY_ETHNICITY, CHART_TYPE_ATTRITION_RATE_BY_GENDER, CHART_TYPE_ETHNICITY, CHART_TYPE_GENDER, CHART_TYPE_HIRE_BY_GENDER, CHART_TYPE_HIRE_MANAGEMENT_LEVEL_BY_ETHNICITY, CHART_TYPE_HIRE_MANAGEMENT_LEVEL_BY_GENDER, CHART_TYPE_HIRING_TREND_BY_ETHNICITY, CHART_TYPE_PROMOTION_BY_GENDER, CHART_TYPE_PROMOTION_TREND_BY_ETHNICITY, COLOR_FEMALE, COLOR_MALE, DASHBOARD_AREA_TOTALS, LABEL_FEMALE, LABEL_MALE } from "../../../Models/Constants";
import { getDashboardData } from "../../../Services/Api";
import BarChart from "../../BarChart/BarChart";
import BarChartStacked from "../../BarChartStacked/BarChartStacked";
import ChartPeople from "../../ChartPeople/ChartPeople";
import HeadCount from "../../HeadCount/HeadCount";
import './DashboardTotalsControl.css';

type DashboardTotalsControlProps = {
    selectedDashboard: DashboardRow | undefined;
    selectedYears: CountRow[] | undefined;
    showCounts: boolean;
    showLogarithmicScale: boolean;
    filters: DataFilterRow[] | undefined;
    fillFilterFieldValues: (filterFieldValuesData: any) => void;
    onClickChartElement: (data: ChartClickEventData) => void
}

const DashboardTotalsControl = ({ selectedDashboard, selectedYears, showCounts, showLogarithmicScale, filters, fillFilterFieldValues, onClickChartElement }: DashboardTotalsControlProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [chartDataByGender, setChartDataByGender] = useState<ChartData[]>([]);
    const [chartDataByEthnicities, setChartDataByEthnicities] = useState<ChartData[]>([]);
    const [chartDataAgeBandByGender, setChartDataAgeBandByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataAttritionRateByGender, setChartDataAttritionRateByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataHiringTrendByEthnicity, setChartDataHiringTrendByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataPromotionTrendByEthnicity, setChartDataPromotionTrendByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataAttritionRateByEthnicity, setChartDataAttritionRateByEthnicity] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataHireByGender, setChartDataHireByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataPromotionByGender, setChartDataPromotionByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataHireManagementLevelByGender, setChartDataHireManagementLevelByGender] = useState<ChartDataMaster | undefined>(undefined);
    const [chartDataHireManagementLevelByEthnicity, setChartDataHireManagementLevelByEthnicity] = useState<ChartDataMaster | undefined>(undefined);

    // console.log('DashboardTotalsControl');

    const fetchDashboardData = (dashboardId: number, years: string, filters: DataFilterRow[] | undefined) => {

        setLoading(true);

        getDashboardData(dashboardId, years, filters, DASHBOARD_AREA_TOTALS)
            .then((data: any) => {

                fillFilterFieldValues(data.data);

                // console.log('data', data);
                let countByGender = data.data.countByGender as CountRow[];
                let countByEthnicity = data.data.countByEthnicity as CountRow[];
                let countByAgeBand = data.data.countByAgeBand as CountRow[];
                let countHireByGender = data.data.countHireByGender as CountRow[];
                let countPromotionByGender = data.data.countPromotionByGender as CountRow[];
                let countAttritionRateByGender = data.data.countAttritionRateByGender as CountRow[];
                let countHireManagementLevelByGender = data.data.countHireManagementLevelByGender as CountRow[];
                let countHiringTrendByEthnicity = data.data.countHiringTrendByEthnicity as CountRow[];
                let countPromotionTrendByEthnicity = data.data.countPromotionTrendByEthnicity as CountRow[];
                let countAttritionRateByEthnicity = data.data.countAttritionRateByEthnicity as CountRow[];
                let countByHireManagementLevelByEthnicity = data.data.countByHireManagementLevelByEthnicity as CountRow[];

                fillLabelsRowFromName(countByAgeBand);
                fillLabelsRowFromName(countHireByGender);
                fillLabelsRowFromName(countPromotionByGender);
                fillLabelsRowFromName(countAttritionRateByGender);
                fillLabelsRowFromName(countHireManagementLevelByGender);
                fillLabelsRowFromName(countHiringTrendByEthnicity);
                fillLabelsRowFromName(countPromotionTrendByEthnicity);
                fillLabelsRowFromName(countAttritionRateByEthnicity);
                fillLabelsRowFromName(countByHireManagementLevelByEthnicity);

                const colorGender = ['#8C8D86', '#E6C069', '#897B61', '#8DAB8E', '#77A2BB', '#DF7689', '#4F504B'];
                const colorEthnicities = ['#8C8D86', '#E6C069', '#897B61', '#8DAB8E', '#77A2BB', '#DF7689', '#4F504B', ...getRandomColors(10)];

                countAttritionRateByEthnicity = swapNames(countAttritionRateByEthnicity);

                fillChartDataByGender(countByGender);
                fillChartDataByEthnicity(countByEthnicity, colorEthnicities);
                setChartDataAgeBandByGender(getChartData(countByAgeBand, colorGender, false, false, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataAttritionRateByGender(getChartData(countAttritionRateByGender, colorGender, false, true, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataHiringTrendByEthnicity(getChartData(countHiringTrendByEthnicity, colorEthnicities, true, false, false));
                setChartDataPromotionTrendByEthnicity(getChartData(countPromotionTrendByEthnicity, colorEthnicities, true, false, false));
                setChartDataAttritionRateByEthnicity(getChartData(countAttritionRateByEthnicity, colorEthnicities, true, true, false));
                setChartDataHireByGender(getChartData(countHireByGender, colorGender, false, false, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataPromotionByGender(getChartData(countPromotionByGender, colorGender, false, false, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataHireManagementLevelByGender(getChartData(countHireManagementLevelByGender, colorGender, true, false, false, [LABEL_MALE, LABEL_FEMALE]));
                setChartDataHireManagementLevelByEthnicity(getChartData(countByHireManagementLevelByEthnicity, colorEthnicities, true, true, false));

                setLoading(false);
            })
            .catch((err: Error) => {
                console.log(err);
                setLoading(false);
            });
    }

    const fillChartDataByGender = (countByGenders: CountRow[]) => {
        let newChartDataByGender = countByGenders.map(x => {
            return {
                groupBy: x.name,
                color: x.name === LABEL_MALE ? COLOR_MALE : COLOR_FEMALE,
                data: x.rows,
            } as ChartData
        });

        // console.log('newChartDataByGender', newChartDataByGender);

        setChartDataByGender(newChartDataByGender);
    }

    const fillChartDataByEthnicity = (countByEthnicities: CountRow[], colorEthnicities: string[]) => {
        let newChartDataByEthnicities = countByEthnicities.sort((a, b) => b.rows - a.rows).map((x, idx) => {
            return {
                groupBy: x.name,
                color: colorEthnicities[idx],
                data: x.rows,
            } as ChartData
        });

        // console.log('newChartDataByEthnicities', newChartDataByEthnicities);

        setChartDataByEthnicities(newChartDataByEthnicities);
    }

    useEffect(() => {
        if (selectedDashboard) {
            let years = '';

            if (selectedYears) {
                years = selectedYears.map(y => y.name).join(',');
            }

            fetchDashboardData(selectedDashboard.dashboardId, years, filters);
        }
    }, [selectedYears, filters])

    return (
        loading ?
            <div className="p-d-flex p-jc-center">
                <div className="p-d-flex p-flex-column p-pt-3">
                    <h2 className="p-m-2 p-p-2 p-as-center">Loading...</h2>
                    <ProgressBar mode="indeterminate" style={{ width: '500px' }} />
                </div>
            </div>
            :
            <div id="chartContainer" className="p-grid p-dir-col">
                <div className="p-col p-grid">
                    <div className="p-col-2 chart-block">
                        <ChartPeople totalLabel="Total HC"
                            chartData={chartDataByGender}
                            chartTypeId={CHART_TYPE_GENDER}
                            groupByFieldName="gender"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-5 chart-block">
                        <BarChart label="Age Band - % of Total"
                            showCounts={showCounts}
                            showLogarithmicScale={showLogarithmicScale}
                            withinEach={false}
                            chartDataMaster={chartDataAgeBandByGender}
                            chartTypeId={CHART_TYPE_AGE_BAND}
                            groupByFieldName="gender"
                            fieldName="subGenerationName"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-5 chart-block">
                        <BarChart label="Age Band - % within each"
                            showCounts={showCounts}
                            showLogarithmicScale={showLogarithmicScale}
                            withinEach={true}
                            chartDataMaster={chartDataAgeBandByGender}
                            chartTypeId={CHART_TYPE_AGE_BAND}
                            groupByFieldName="gender"
                            fieldName="subGenerationName"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>
                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-2 chart-block p-pb-0">
                        <HeadCount label="Current Headcount"
                            chartData={chartDataByGender}
                            showCounts={showCounts} height="200px"
                            chartTypeId={CHART_TYPE_GENDER}
                            groupByFieldName="gender"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-2 chart-block p-pb-0">
                        <BarChartStacked label="Hires by Gender"
                            showCounts={showCounts}
                            withinEach={true}
                            chartDataMaster={chartDataHireByGender}
                            position='bottom'
                            height="200px"
                            chartTypeId={CHART_TYPE_HIRE_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="hireYr"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-2 chart-block p-pb-0">
                        <BarChartStacked label="% of Promotions by Gender"
                            showCounts={showCounts}
                            withinEach={true}
                            chartDataMaster={chartDataPromotionByGender}
                            position='bottom'
                            height="200px"
                            chartTypeId={CHART_TYPE_PROMOTION_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="ye"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-3 chart-block p-pb-0">
                        <BarChart label="Attrition Rate by Gender"
                            showCounts={showCounts}
                            showLogarithmicScale={showLogarithmicScale}
                            withinEach={false}
                            customTotals={true}
                            chartDataMaster={chartDataAttritionRateByGender}
                            position='bottom'
                            height="200px"
                            chartTypeId={CHART_TYPE_ATTRITION_RATE_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="ye"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-3 chart-block p-pb-0">
                        <BarChartStacked label="Hires Current Yr - Mgmt Level"
                            showCounts={showCounts}
                            withinEach={true}
                            horizontal={true}
                            chartDataMaster={chartDataHireManagementLevelByGender}
                            position='bottom'
                            height="200px"
                            chartTypeId={CHART_TYPE_HIRE_MANAGEMENT_LEVEL_BY_GENDER}
                            groupByFieldName="gender"
                            fieldName="managementLevel"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                </div>
                <div className="p-col p-grid p-pt-0">
                    <div className="p-col-2 chart-block p-pb-0">
                        <HeadCount label="Current Headcount"
                            chartData={chartDataByEthnicities}
                            showCounts={showCounts} isPie={true}
                            position="bottom" height="300px"
                            chartTypeId={CHART_TYPE_ETHNICITY}
                            groupByFieldName="ethnicity"
                            onClickElement={onClickChartElement}
                        />
                    </div>
                    <div className="p-col-10 p-pb-0">
                        <div className="p-grid">
                            <div className="p-col-6 chart-block p-pb-0">
                                <BarChart label="Hiring Trend by Ethnicity"
                                    showCounts={showCounts}
                                    showLogarithmicScale={showLogarithmicScale}
                                    withinEach={true}
                                    customTotals={true}
                                    chartDataMaster={chartDataHiringTrendByEthnicity}
                                    position='bottom'
                                    height="200px"
                                    rotation={-90}
                                    chartTypeId={CHART_TYPE_HIRING_TREND_BY_ETHNICITY}
                                    groupByFieldName="ethnicity"
                                    fieldName="hireYr"
                                    onClickElement={onClickChartElement}
                                />
                            </div>
                            <div className="p-col-6 chart-block p-pb-0">
                                <BarChart label="% of Promotions Trend by Ethnicity"
                                    showCounts={showCounts}
                                    showLogarithmicScale={showLogarithmicScale}
                                    withinEach={true}
                                    customTotals={true}
                                    chartDataMaster={chartDataPromotionTrendByEthnicity}
                                    position='bottom'
                                    height="200px"
                                    rotation={-90}
                                    chartTypeId={CHART_TYPE_PROMOTION_TREND_BY_ETHNICITY}
                                    groupByFieldName="ethnicity"
                                    fieldName="ye"
                                    onClickElement={onClickChartElement}
                                />
                            </div>
                            <div className="p-col-6 chart-block p-pb-0">
                                <BarChart label="Attrition Rate Trend by Ethnicity"
                                    showCounts={showCounts}
                                    showLogarithmicScale={showLogarithmicScale}
                                    withinEach={false}
                                    customTotals={true}
                                    chartDataMaster={chartDataAttritionRateByEthnicity}
                                    position='bottom'
                                    height="200px"
                                    rotation={-90}
                                    chartTypeId={CHART_TYPE_ATTRITION_RATE_BY_ETHNICITY}
                                    groupByFieldName="ethnicity"
                                    fieldName="ye"
                                    onClickElement={onClickChartElement}
                                />
                            </div>
                            <div className="p-col-6 chart-block p-pb-0">
                                <BarChartStacked label="Current Yr Hires - Mgmt Level Ethnicity"
                                    showCounts={showCounts}
                                    withinEach={true}
                                    horizontal={true}
                                    chartDataMaster={chartDataHireManagementLevelByEthnicity}
                                    position='bottom'
                                    height="200px"
                                    chartTypeId={CHART_TYPE_HIRE_MANAGEMENT_LEVEL_BY_ETHNICITY}
                                    groupByFieldName="ethnicity"
                                    fieldName="managementLevel"
                                    onClickElement={onClickChartElement}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default DashboardTotalsControl;
