import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../Helpers/Hooks";
import { DASHBOARD_AREA_ETHNICITY, DASHBOARD_AREA_GENDER as DASHBOARD_AREA_GENDER, DASHBOARD_AREA_TERM_ETHNICITY as DASHBOARD_AREA_TERMS_ETHNICITY, DASHBOARD_AREA_TERM_GENDER as DASHBOARD_AREA_TERMS_GENDER, DASHBOARD_AREA_TOTALS as DASHBOARD_AREA_TOTALS, DATA_FILTER_FIELDS, LABEL_BLANK } from "../../Models/Constants";
import { getDashboards, getFilterYears } from "../../Services/Api";
import DashboardEthnicityControl from "../DashboardAreas/DashboardEthnicityControl/DashboardEthnicityControl";
import DashboardGenderControl from "../DashboardAreas/DashboardGenderControl/DashboardGenderControl";
import DashboardTermsEthnicityControl from "../DashboardAreas/DashboardTermsEthnicityControl/DashboardTermsEthnicityControl";
import DashboardTermsGenderControl from "../DashboardAreas/DashboardTermsGenderControl/DashboardTermsGenderControl";
import DashboardTotalsControl from "../DashboardAreas/DashboardTotalsControl/DashboardTotalsControl";
import FiltersComponent from "../FiltersComponent/FiltersComponent";
import GridDataComponent from "../Modals/GridDataComponent/GridDataComponent";
import './ViewDashboardControl.css';

const ViewDashboardControl = () => {
    const [dashboards, setDashboards] = useState<DashboardRow[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<DashboardRow | undefined>();
    const [years, setYears] = useState<CountRow[]>([]);
    const [selectedYears, setSelectedYears] = useState<CountRow[] | undefined>();
    const [showCounts, setShowCounts] = useState(false);
    const [showLogarithmicScale, setShowLogarithmicScale] = useState(false);
    const [filters, setFilters] = useState<DataFilterRow[] | undefined>(undefined)
    const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
    const [fields, setFields] = useState<DataFieldRow[] | undefined>(undefined)

    const [displayGridDataModal, setDisplayGridDataModal] = useState<boolean>(false);
    const [chartClickEventData, setChartClickEventData] = useState<ChartClickEventData | undefined>(undefined);

    const dashboardAreas: DashboardAreaRow[] = [
        { dashboardAreaId: DASHBOARD_AREA_TOTALS, name: 'Totals' },
        { dashboardAreaId: DASHBOARD_AREA_GENDER, name: 'Gender' },
        { dashboardAreaId: DASHBOARD_AREA_ETHNICITY, name: 'Ethnicity' },
        { dashboardAreaId: DASHBOARD_AREA_TERMS_GENDER, name: 'Terms Gender' },
        { dashboardAreaId: DASHBOARD_AREA_TERMS_ETHNICITY, name: 'Terms Ethnicity' },
    ]

    const [selectedDashboardArea, setSelectedDashboardArea] = useState<DashboardAreaRow | undefined>(dashboardAreas[0]);

    const size = useWindowSize();

    const fetchDashboards = () => {
        getDashboards()
            .then((data: any) => {
                let dashboards = data.data as DashboardRow[];
                // console.log('dashboards', dashboards);

                setDashboards(dashboards);

                if (dashboards.length > 0) {
                    setSelectedDashboard(dashboards[0]);
                }
            })
            .catch((err: Error) => console.log(err));
    }


    const fillCountRowLabels = (countRows: CountRow[]) => {
        countRows.forEach(cr => cr.label = `${cr.name ? cr.name : LABEL_BLANK} (${cr.rows.toLocaleString('en-US')})`);
    }

    const fillFilterFieldValues = (filterFieldValuesData: any) => {
        let fields = [...DATA_FILTER_FIELDS];

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];

            field.availableValues = filterFieldValuesData[field.fieldName + 'Count'];
        }

        // console.log('fields', fields);

        setFields(fields);
    }

    const fetchFilters = (dashboardId: number) => {
        let yearsFilter = '';

        if (selectedYears) {
            yearsFilter = selectedYears.map(y => y.name).join(',');
        }

        getFilterYears(dashboardId)
            .then((data: any) => {
                // console.log('data', data.data);
                let yeCount = data.data.yeCount as CountRow[];
                // console.log('yeCount', yeCount);

                fillCountRowLabels(yeCount);

                yeCount.sort((a, b) => b.label.localeCompare(a.label));

                setYears(yeCount);

                if (yeCount.length > 0) {
                    // console.log(years[0]);
                    setSelectedYears([yeCount[0]]);
                }
            })
            .catch((err: Error) => console.log(err));
    }

    useEffect(() => {
        // console.log('useEffect');
        fetchDashboards();
        setYears([]);
    }, [])

    useEffect(() => {

        // console.log('useEffect', selectedDashboard);

        if (selectedDashboard) {
            fetchFilters(selectedDashboard.dashboardId);
        }
    }, [selectedDashboard])

    const onClickChartElement = (data: ChartClickEventData) => {
        // console.log('onClickChartElement', data);
        setChartClickEventData(data);
        setDisplayGridDataModal(true);
    }

    const getChartComponent = () => {
        switch (selectedDashboardArea?.dashboardAreaId) {
            case DASHBOARD_AREA_TOTALS:
                return <DashboardTotalsControl selectedDashboard={selectedDashboard}
                    selectedYears={selectedYears} showCounts={showCounts} showLogarithmicScale={showLogarithmicScale}
                    filters={filters} fillFilterFieldValues={fillFilterFieldValues}
                    onClickChartElement={onClickChartElement} />;

            case DASHBOARD_AREA_GENDER:
                return <DashboardGenderControl selectedDashboard={selectedDashboard}
                    selectedYears={selectedYears} showCounts={showCounts} showLogarithmicScale={showLogarithmicScale}
                    filters={filters} fillFilterFieldValues={fillFilterFieldValues}
                    onClickChartElement={onClickChartElement} />;

            case DASHBOARD_AREA_ETHNICITY:
                return <DashboardEthnicityControl selectedDashboard={selectedDashboard}
                    selectedYears={selectedYears} showCounts={showCounts} showLogarithmicScale={showLogarithmicScale}
                    filters={filters} fillFilterFieldValues={fillFilterFieldValues}
                    onClickChartElement={onClickChartElement} />;

            case DASHBOARD_AREA_TERMS_GENDER:
                return <DashboardTermsGenderControl selectedDashboard={selectedDashboard}
                    selectedYears={selectedYears} showCounts={showCounts} showLogarithmicScale={showLogarithmicScale}
                    filters={filters} fillFilterFieldValues={fillFilterFieldValues}
                    onClickChartElement={onClickChartElement} />;

            case DASHBOARD_AREA_TERMS_ETHNICITY:
                return <DashboardTermsEthnicityControl selectedDashboard={selectedDashboard}
                    selectedYears={selectedYears} showCounts={showCounts} showLogarithmicScale={showLogarithmicScale}
                    filters={filters} fillFilterFieldValues={fillFilterFieldValues}
                    onClickChartElement={onClickChartElement} />;
        }
    }

    const showFilterSidebar = () => {
        setFilterSidebarVisible(true);
    }

    const exportPDF = () => {

        const chartContainer = document.getElementById('chartContainer');

        if (chartContainer) {
            html2canvas(chartContainer).then(function (canvas) {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                });

                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 10;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                const x = (pdf.internal.pageSize.getWidth() - pdfWidth) / 2;
                const y = (pdf.internal.pageSize.getHeight() - pdfHeight) / 2;

                // console.log('pdf', pdf);
                pdf.addImage(imgData, 'PNG', x, y, pdfWidth, pdfHeight, undefined, 'FAST', undefined);
                pdf.save("Export.pdf");
            });
        }
    }

    return (
        <div>
            <div className="p-formgroup-inline p-pl-0">
                <div className="p-field">
                    <label htmlFor="dashboard">Dashboard</label>
                    <Dropdown id="dashboard" optionLabel="name" value={selectedDashboard} options={dashboards} onChange={(e) => setSelectedDashboard(e.value)} placeholder="Select a Dashboard" />
                </div>
                <div className="p-field">
                    <label htmlFor="dashboardArea">Dashboard Area</label>
                    <Dropdown id="dashboardDetail" optionLabel="name" value={selectedDashboardArea} options={dashboardAreas} onChange={(e) => setSelectedDashboardArea(e.value)} placeholder="Select an area" />
                </div>
                <div className="p-field p-pl-3 p-pr-3">
                    <label htmlFor="year">Year</label>
                    <MultiSelect id="year" display="chip" optionLabel="label" value={selectedYears} options={years} onChange={(e) => { console.log(selectedYears); setSelectedYears(e.value) }} placeholder="Select an Year" />
                </div>
                <div className="p-field-checkbox p-mt-1">
                    <label htmlFor="showCounts">Counts</label>
                    <InputSwitch id="showCounts" checked={showCounts} onChange={(e) => setShowCounts(e.value)} />
                </div>
                <div className="p-field-checkbox p-mt-1">
                    <label htmlFor="showLogarithmicScale">Logarithmic</label>
                    <InputSwitch id="showLogarithmicScale" checked={showLogarithmicScale} onChange={(e) => setShowLogarithmicScale(e.value)} />
                </div>
                <div className="p-field-checkbox">
                    <label htmlFor="showFilter">Filters</label>
                    <Button id="showFilter" icon="pi pi-filter" className="p-button-rounded p-button-outlined" onClick={() => showFilterSidebar()} />
                </div>
                <div className="p-field-checkbox">
                    <label htmlFor="exportPDF">Export PDF</label>
                    <Button id="exportPDF" icon="pi pi-file-pdf" className="p-button-rounded p-button-outlined" onClick={() => exportPDF()} />
                </div>
            </div>
            <div className="chart-component">
                {getChartComponent()}
            </div>
            <FiltersComponent fields={fields} filterSidebarVisible={filterSidebarVisible} setFilterSidebarVisible={setFilterSidebarVisible} onFiltersChanged={(newFilters) => setFilters(newFilters)} />
            <GridDataComponent displayModal={displayGridDataModal} setDisplayModal={setDisplayGridDataModal}
                chartClickEventData={chartClickEventData} selectedDashboard={selectedDashboard} selectedYears={selectedYears}
                filters={filters} />
        </div>
    )
}

export default ViewDashboardControl;
