import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../../Helpers/Hooks";
import { getGridData } from "../../../Services/Api";
import * as xlsx from 'xlsx';
import * as fileSaver from 'file-saver';
import { DATA_FIELDS, DATA_FILTER_FIELDS } from "../../../Models/Constants";

type GridDataComponentProps = {
    displayModal: boolean;
    setDisplayModal: Dispatch<SetStateAction<boolean>>;
    selectedDashboard: DashboardRow | undefined;
    selectedYears: CountRow[] | undefined;
    filters: DataFilterRow[] | undefined;
    chartClickEventData: ChartClickEventData | undefined;
}

const GridDataComponent = ({ displayModal, setDisplayModal, chartClickEventData, selectedDashboard, selectedYears, filters }: GridDataComponentProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<GridDataRow[]>([]);
    const dataTableRef = useRef<DataTable>(null);
    const [startRow, setStartRow] = useState(0);
    const [rowsCount, setRowsCount] = useState(8);
    const [totalRecords, setTotalRecords] = useState<number>(10);

    const size = useWindowSize();

    // console.log('GridDataComponent', chartClickEventData);

    const fetchGridData = useCallback((dashboardId: number, chartClickEventData: ChartClickEventData | undefined, years: string, filters: DataFilterRow[] | undefined) => {

        if (selectedDashboard && selectedYears && chartClickEventData) {
            setLoading(true);

            getGridData(dashboardId, chartClickEventData, years, filters, startRow, rowsCount)
                .then((data: any) => {

                    const gridData = data.data.gridData as GridDataRow[];
                    const totalCount = data.data.totalCount as number;

                    setData(gridData);

                    setTotalRecords(totalCount);
                    setLoading(false);
                })
                .catch((err: Error) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    }, [startRow, rowsCount]);

    const exportExcel = () => {
        if (selectedDashboard && selectedYears && chartClickEventData) {
            const years = getYears();
            getGridData(selectedDashboard.dashboardId, chartClickEventData, years, filters, startRow, 999999)
                .then((data: any) => {

                    const gridData = data.data.gridData as GridDataRow[];
                    const totalCount = data.data.totalCount as number;

                    let items = [...gridData] as any[];

                    for (let i = 0; i < items.length; i++) {
                        let item = items[i];

                        for (let j = 0; j < DATA_FIELDS.length; j++) {
                            const f = DATA_FIELDS[j];

                            item[f.label] = item[f.fieldName];
                            delete item[f.fieldName];
                        }

                        delete item['dashboardDetailId'];
                        delete item['dashboardId'];
                        delete item['createdOn'];
                        delete item['createdBy'];
                        delete item['generationId'];
                        delete item['minAge'];
                        delete item['maxAge'];
                    }

                    const worksheet = xlsx.utils.json_to_sheet(items);
                    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                    saveAsExcelFile(excelBuffer, 'data');
                })
                .catch((err: Error) => {
                    console.log(err);
                });
        }
    }

    const saveAsExcelFile = (buffer: any, fileName: string) => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        fileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    const getYears = (): string => {
        let years = '';

        if (selectedYears) {
            years = selectedYears.map(y => y.name).join(',');
        }

        return years;
    }

    useEffect(() => {
        if (displayModal && selectedDashboard && selectedYears) {
            const years = getYears();
            fetchGridData(selectedDashboard.dashboardId, chartClickEventData, years, filters);
        }
    }, [displayModal, selectedYears, chartClickEventData, filters, startRow, rowsCount])

    useEffect(() => {
        if (selectedDashboard) {
            setStartRow(0);

            let count = Math.floor((size.height - 380) / 64);

            if (count < 3)
                count = 3;

            setRowsCount(count);
        }
    }, [displayModal])

    const header = (
        <div className="p-d-flex export-buttons">
            <Button type="button" icon="pi pi-download" iconPos="right" label="Export" onClick={() => exportExcel()} data-pr-tooltip="Export as CSV" />
        </div>
    );

    const onPage = (first: number, rows: number) => {
        setStartRow(first);
        setRowsCount(rows);
    }

    return (
        <Dialog header="Data" visible={displayModal} modal onHide={() => setDisplayModal(false)}>
            <DataTable ref={dataTableRef} header={header} value={data} resizableColumns columnResizeMode="fit"
                scrollable paginator paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} / {last} ({totalRecords})"
                rows={rowsCount} totalRecords={totalRecords}
                lazy first={startRow} onPage={(e) => onPage(e.first, e.rows)} loading={loading} style={{ width: `${size.width * 0.8}px` }}>
                <Column field="personNum" header="Person Num" headerStyle={{ width: '150px' }}></Column>
                <Column field="hireDate" header="Hire Date" headerStyle={{ width: '150px' }}></Column>
                <Column field="terminationDate" header="Termination Date" headerStyle={{ width: '150px' }}></Column>
                <Column field="terminationType" header="Termination Type" headerStyle={{ width: '150px' }}></Column>
                <Column field="terminationReason" header="Termination Reason" headerStyle={{ width: '150px' }}></Column>
                <Column field="jobGroupLevel1" header="Job Group Level 1" headerStyle={{ width: '150px' }}></Column>
                <Column field="jobGroupLevel2" header="Job Group Level 2" headerStyle={{ width: '150px' }}></Column>
                <Column field="location" header="Location" headerStyle={{ width: '150px' }}></Column>
                <Column field="managementLevel" header="Management Level" headerStyle={{ width: '150px' }}></Column>
                <Column field="fte" header="FTE" headerStyle={{ width: '150px' }}></Column>
                <Column field="gender" header="Gender" headerStyle={{ width: '150px' }}></Column>
                <Column field="age" header="Age" headerStyle={{ width: '150px' }}></Column>
                <Column field="count" header="Count" headerStyle={{ width: '150px' }}></Column>
                <Column field="ye" header="YE" headerStyle={{ width: '150px' }}></Column>
                <Column field="asOf" header="As Of" headerStyle={{ width: '150px' }}></Column>
                <Column field="ethnicity" header="Ethnicity" headerStyle={{ width: '150px' }}></Column>
                <Column field="ageBand" header="Age Band" headerStyle={{ width: '150px' }}></Column>
                <Column field="hireYr" header="Hire Yr" headerStyle={{ width: '150px' }}></Column>
                <Column field="fulltimePartime" header="Fulltime/Partime" headerStyle={{ width: '150px' }}></Column>
                <Column field="workerStatus" header="Worker Status" headerStyle={{ width: '150px' }}></Column>
                <Column field="promotion" header="Promotion" headerStyle={{ width: '150px' }}></Column>
            </DataTable>
        </Dialog >
    )
}

export default GridDataComponent;