import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { DATA_FILTER_FIELD_TYPE_DATE, DATA_FILTER_FIELD_TYPE_MULTISELECT, DATA_FILTER_FIELD_TYPE_NUMBER, LABEL_BLANK } from '../../Models/Constants';
import './FilterField.css';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';

type FilterFieldProps = {
    field: DataFieldRow;
    filter: DataFilterRow | undefined;
    onFilterChanged: (filter: DataFilterRow) => void;
}

const FilterField = ({ field, filter, onFilterChanged }: FilterFieldProps) => {
    const [filterValues, setFilterValues] = useState<CountRow[] | undefined>(undefined);
    const [filterDates, setFilterDates] = useState<Date[] | undefined>(undefined);
    const [filterNumbers, setFilterNumbers] = useState<number[] | undefined>(undefined);

    useEffect(() => {
        if (filter) {
            const fv = field.availableValues.filter(x => filter && filter.filterValues && filter.filterValues.findIndex(f => f === x.name) !== -1);
            setFilterValues(fv);
            setFilterDates(filter.filterDates);
            setFilterNumbers(filter.filterNumbers);
        }
    }, []);

    const onChangeFilterValues = (filterValues: CountRow[] | undefined) => {
        setFilterValues(filterValues);

        // console.log('onChangeFilterValues', {
        //     fieldName: field.fieldName,
        //     filterValues: filterValues?.map(x => x.name),
        //     filterDates: filterDates,
        //     filterNumbers: filterNumbers,
        // } as DataFilterRow);

        onFilterChanged({
            fieldName: field.fieldName,
            filterValues: filterValues && filterValues.length > 0 ? filterValues?.map(x => x.name) : undefined,
            filterDates: filterDates,
            filterNumbers: filterNumbers,
        } as DataFilterRow)
    }

    const onChangeFilterDates = (filterDates: Date[] | undefined) => {
        setFilterDates(filterDates);

        // console.log('onChangeFilterDates', {
        //     fieldName: field.fieldName,
        //     filterValues: filterValues?.map(x => x.name),
        //     filterDates: filterDates,
        //     filterNumbers: filterNumbers,
        // } as DataFilterRow);

        onFilterChanged({
            fieldName: field.fieldName,
            filterValues: filterValues?.map(x => x.name),
            filterDates: filterDates,
            filterNumbers: filterNumbers,
        } as DataFilterRow)
    }

    const onChangeFilterNumbers = (filterNumbers: number[] | undefined) => {
        setFilterNumbers(filterNumbers);

        // console.log('onChangeFilterNumbers', {
        //     fieldName: field.fieldName,
        //     filterValues: filterValues?.map(x => x.name),
        //     filterDates: filterDates,
        //     filterNumbers: filterNumbers,
        // } as DataFilterRow);

        onFilterChanged({
            fieldName: field.fieldName,
            filterValues: filterValues?.map(x => x.name),
            filterDates: filterDates,
            filterNumbers: filterNumbers,
        } as DataFilterRow)
    }

    const valueTemplate = (option: any) => {
        return (
            <div className="value-item">
                <div>{option.name !== '' ? option.name : LABEL_BLANK} ({option.rows})</div>
            </div>
        );
    }

    const selectedValuesTemplate = (option: any) => {
        return (
            option &&
            <div className="value-item value-item-value">
                <div>{option.name}</div>
            </div>
        );
    }

    const getFilterNumber = (idx: number) => {
        if (filterNumbers) {
            return filterNumbers[idx];
        } else {
            return undefined;
        }
    }

    const setFilterNumber = (idx: number, value: number) => {
        let newFilterNumbers: number[] = [field.minValue, field.maxValue];

        if (filterNumbers) {
            newFilterNumbers = [...filterNumbers];
        }

        newFilterNumbers[idx] = value;
        onChangeFilterNumbers(newFilterNumbers);
    }

    const getFieldComponent = (field: DataFieldRow) => {
        switch (field.fieldType) {
            case DATA_FILTER_FIELD_TYPE_MULTISELECT:
                return (
                    <MultiSelect value={filterValues} options={field.availableValues}
                        onChange={(e) => onChangeFilterValues(e.value)}
                        optionLabel="label" placeholder={`Select ${field.label}`} filter className="multiselect-custom"
                        itemTemplate={valueTemplate} selectedItemTemplate={selectedValuesTemplate} />
                )
            case DATA_FILTER_FIELD_TYPE_DATE:
                return (
                    <Calendar id="range" value={filterDates}
                        onChange={(e) => onChangeFilterDates(e.value as any[])}
                        showIcon
                        selectionMode="range" readOnlyInput />
                )
            case DATA_FILTER_FIELD_TYPE_NUMBER:
                return (
                    <div className="p-fluid p-formgrid p-grid p-pt-2">
                        <div className="p-field p-col">
                            <label htmlFor="minValue">Min Value</label>
                            <InputNumber id="minValue" value={getFilterNumber(0)} onValueChange={(e) => setFilterNumber(0, e.value)} />
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="maxValue">Max Value</label>
                            <InputNumber id="maxValue" value={getFilterNumber(1)} onValueChange={(e) => setFilterNumber(1, e.value)} />
                        </div>
                    </div>
                )
        }
    }

    return (
        <div>
            <Divider align="center">
                <b>{field.label}</b>
            </Divider>
            {
                getFieldComponent(field)
            }
        </div>
    )
}

export default FilterField;